<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img alt="homepage" src="../assets/Terminal.png">
    <p>
      For a guide and recipes on AI powered, economic efficient Cross Border Logistics Solutions<br>
      check out the
      <a href="" target="_blank" rel="noopener">Cross-Border-Flex-Combo</a>.
    </p>

    <h3>Essential Links</h3>
    <ul>
      <li><router-link to="/login" rel="noopener">Sign-In</router-link></li>
      <li><router-link to="" rel="noopener">Sign-Up</router-link></li>
      <li><a href="" target="_blank" rel="noopener">Twitter</a></li>

    </ul>
    <h3>Ecosystem</h3>
    <ul>
      <li><a href="" target="_blank" rel="noopener">Freight-Forwarding</a></li>
      <li><a href="" target="_blank" rel="noopener">Custom-House-Broker</a></li>
      <li><a href="" target="_blank" rel="noopener">Dray</a></li>
      <li><a href="" target="_blank" rel="noopener">Warehouse</a></li>
      <li><a href="" target="_blank" rel="noopener">Courier-Service</a></li>
      <li><a href="" target="_blank" rel="noopener">Truck</a></li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 30px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
